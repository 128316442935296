<!--
  Примеры внизу
-->

<template>

  <v-dialog v-model="show" max-width="550px" scrollable persistent>

    <v-card>
      <v-progress-linear
          :active="loading"
          color="primary"
          indeterminate
          absolute
          bottom
      ></v-progress-linear>

      <v-card-title class="text-h6 primary ztitle">

        <slot name="title-left"/>

        <v-spacer></v-spacer>

        <slot name="title-right"/>

        <v-btn class="mx-0 ztitle" icon @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>

      </v-card-title>

      <v-card-text :class="[wait ? 'overlay_limit' : '' ]">
        <v-container>
          <slot/>
        </v-container>

        <v-overlay
            absolute
            :opacity=0.8
            color="white"
            :value="wait"
        >
          <v-progress-circular
              indeterminate
              size="30"
              color="teal"
          ></v-progress-circular>
        </v-overlay>


      </v-card-text>

      <v-card-actions class="innershadow">
        <v-btn v-if="hasDeleteListener()"
               @click="onDelete()"
               class="softvision btndel"
               icon
        >
          <v-icon>mdi-delete-forever</v-icon>
        </v-btn>
        <v-spacer/>
        <slot name="actions" :close="dialog_close"/>
        <v-btn text @click="show = false">
          {{ btnCloseName }}
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>

export default {
  props: {
    value: Boolean,
    loading: {type: Boolean, default: false},
    wait: {type: Boolean, default: false},
    btnCloseName: {type: String, default: 'Отмена'},
  },
  data: () => ({}),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
        this.$emit('close', value)
      }
    }
  },
  methods: {
    onDelete() {
      if (!window.confirm("Действительно хотите удалить?")) return
      this.$emit('delete')
      // this.dialog_close()
    },
    dialog_close() {
      this.show = false
    },
    hasDeleteListener() {
      // window.console.log("this.$listeners",this.$listeners)
      return this.$listeners && this.$listeners.delete
    }
  },
}
</script>

<style scoped>

.overlay_limit {
  position: relative;
}

.innershadow {
  box-shadow: 0px -4px 12px 0px rgb(224 224 224) inset;
}

.ztitle {
  color: white !important;
  /*text-overflow: ellipsis;*/
  /*max-width: 550px;*/
  /*overflow: hidden;*/
  /*white-space: nowrap;*/
}

.btndel {
  color: #d2d2d2 !important;
}

.btndel:hover {
  color: #ff2121 !important;
}

</style>


<!--


   <v.dialog v-model="show" :loading="loading" :wait="wait" @delete="bntDetele" btnCloseName="Закрыть">

-->
